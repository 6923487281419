"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.clientInfo = void 0;
var axios_1 = require("axios");
var next_i18next_1 = require("next-i18next");
var message_1 = require("@/constants/message");
var credential_1 = require("@/utils/credential");
var error_notifier_1 = require("@/utils/error-notifier");
var sweetalert_1 = require("@/utils/sweetalert");
var next_i18next_config_1 = require("next-i18next.config");
exports.clientInfo = "web2/".concat(process.env.NEXT_PUBLIC_VERSION);
var onResponseError = function (error) { return __awaiter(void 0, void 0, void 0, function () {
    var errorContext, isValidErrorContext, shouldThrowError, errorToastMessage;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        errorContext = (_a = error === null || error === void 0 ? void 0 : error.config) === null || _a === void 0 ? void 0 : _a.errorContext;
        isValidErrorContext = Boolean(errorContext) &&
            typeof errorContext === 'object' &&
            !Array.isArray(errorContext);
        shouldThrowError = !Boolean((_b = error === null || error === void 0 ? void 0 : error.config) === null || _b === void 0 ? void 0 : _b.ignoreThrowError);
        errorToastMessage = (_c = error === null || error === void 0 ? void 0 : error.config) === null || _c === void 0 ? void 0 : _c.errorToastMessage;
        if (((_d = error.response) === null || _d === void 0 ? void 0 : _d.status) === 500) {
            sweetalert_1.default.fire({
                icon: 'warning',
                text: message_1.GENERIC_API_ERR_MESSAGE,
                confirmButtonText: '好',
            });
        }
        (0, error_notifier_1.notify)({
            err: error,
            context: __assign({ key: 'response error' }, (isValidErrorContext ? errorContext : {})),
            toast: errorToastMessage
                ? {
                    type: 'error',
                    props: {
                        message: errorToastMessage,
                    },
                }
                : undefined,
        });
        if (shouldThrowError) {
            return [2 /*return*/, Promise.reject(error)];
        }
        return [2 /*return*/];
    });
}); };
var onResponse = function (response) {
    credential_1.default.update(response);
    return response;
};
var axiosInstance = axios_1.default.create({
    baseURL: process.env.NEXT_PUBLIC_BACKEND_API,
    headers: {
        'Client-Info': exports.clientInfo,
    },
});
axiosInstance.interceptors.response.use(onResponse, onResponseError);
var fire = function (config) {
    var headers = __assign(__assign({ 'Content-Type': 'application/json', 'Client-Lang': (next_i18next_1.i18n === null || next_i18next_1.i18n === void 0 ? void 0 : next_i18next_1.i18n.resolvedLanguage) || next_i18next_config_1.LOCALE_TW }, credential_1.default.get()), (config.headers || {}));
    return axiosInstance.request(__assign(__assign({}, config), { headers: headers }));
};
exports.default = fire;
