import { isEmpty } from 'lodash-es'
import * as Sentry from '@sentry/react'

import {
  EXPECTED_ERROR_CLASS,
  EXPECTED_ERROR_CODES,
  EXPECTED_ERROR_DETAILS,
  EXPECTED_ERROR_MESSAGES,
} from './constants'

import wuToast, { WuToastProps, WuToastTypes } from '@/components/toast'

export const isExpectedErrorMessages = (errorMessage = '') => {
  const error = typeof errorMessage === 'string' ? errorMessage : ''

  const expectedErrorMessages = [
    ...Object.values(EXPECTED_ERROR_CLASS),
    ...Object.values(EXPECTED_ERROR_MESSAGES),
  ]

  return expectedErrorMessages.some(message => error.includes(message))
}

const isExpectedError = (error: any) => {
  const responseErrorCode: string | undefined = error?.response?.data?.errors?.[0]?.code
  const responseErrorDetail: string | undefined =
    error?.response?.data?.errors?.[0]?.detail
  const errorStatusCode: number | undefined = error?.response?.status
  const errorMessage: string | undefined = error?.message
  const shouldIgnoreMessage = isExpectedErrorMessages(errorMessage)

  const expectedError =
    (responseErrorCode &&
      Object.values(EXPECTED_ERROR_CODES).includes(responseErrorCode)) ||
    (responseErrorDetail &&
      Object.values(EXPECTED_ERROR_DETAILS).includes(responseErrorDetail)) ||
    errorStatusCode === 404 ||
    shouldIgnoreMessage

  return expectedError
}

export const setUser = (userInfo: {
  id: string | undefined
  name: string
  email: string
}) => {
  if (isEmpty(userInfo)) return

  const { id, name, email } = userInfo
  const userId = id ? id.toString() : undefined
  Sentry.setUser({ id: userId, username: name, email: email })
}

export const notify = ({
  err,
  context = {},
  toast,
}: {
  err: Error | unknown
  context: any
  toast?: { type: (typeof WuToastTypes)[number]; props: WuToastProps }
}) => {
  const isExpectedErr = isExpectedError(err)
  if (err instanceof Error && !isExpectedErr) {
    Sentry.captureException(err, {
      extra: context,
    })
  }

  if (toast) {
    wuToast[toast.type](toast.props)
  }
}

export const isNotifiableError = (error: any): error is Error => {
  return (
    error instanceof Error ||
    (typeof error.errorClass === 'string' && typeof error.errorMessage === 'string') ||
    (typeof error.name === 'string' && typeof error.message === 'string') ||
    typeof error === 'string'
  )
}
const ErrorNotifier = {
  setUser,
  notify,
}

export default ErrorNotifier
